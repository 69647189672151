
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');
*{
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}
/* body{
    max-width: 1600px;
    margin: auto;
    font-family:'Inter', sans-serif;;
} */

/* .logo_img{
    max-width: 160px; 
    height: auto; 
    object-fit: cover;
} */
.send_otp_btn2{
    border-radius: 16px;
    background-color: #2CE0B6;
    
    color: #F7FCFF;
    font-weight: 500;
    padding: 8px;
    align-items: center;
    text-align: center;
    height: 35px !important;
    border: none;
    min-width: fit-content;

}
#forgotPasswordFormRecruiterPage{
    transition: 0.7s;
    left: 100%;
}
.button1{
    margin-right: 10px; border-radius: 15px; border: 1px solid #3B697C; color: #3B697C; font-weight: 600;
}
.button1:hover{
    color: white;
    background-color:#3B697C;
}
.button1:focus{
    background-color: #BBE6F9;
    color: white;
    outline: none !important;
    box-shadow: none;
}

.button2{
    margin-right: 10px; border-radius: 15px; border: 1px solid #41A991; color: #41A991; font-weight: 600;
}

.button2:hover{
    color: #41A991;
    background-color: #BBF9EA;
}
.button2:focus{
    background-color: #BBF9EA;
    color: white;
    outline: none !important;
    box-shadow: none;
}


.button3:focus{
    outline: none !important;
    box-shadow: none;
}
.rec-banner-col{
    position: relative;
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: 90% 90%;
    background-position: 45% 55%;
    overflow-x: hidden;
}

.log_form{
    position: relative;
    width: 40%;
    max-width: 450px;
    height: 430px;
    border-radius: 16px;
    background-color: white;
    border: 1px solid #E5E5E5;;
    left: 50%;
    top: 8%;
    padding: 16px;
    transition: 0.7s;
    overflow-x: hidden !important; 
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
}

.log_form::-webkit-scrollbar {
    display: none;
}

.class12{
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    height: 370px !important;
    left: 5%;
    width: 90%;
    transition: 0.6s all linear;
   
}
#recruiterPageForm_step1{
    transition: 0.7s;
    left: 100%;
}
#recruiterPageForm_step2{
    transition: 0.7s;
    left: 100%;
}
#recruiterPageForm_step3{
    transition: 0.7s;
    left: 100%;
}
.button_div{
    display: flex;
    justify-content: space-evenly;
    font-size: 12px;
}
.login_btn{
    background-color: #F6F6F6;
    color: #3B7C6D;
    border-radius: 16px 16px 0px 0px;
    font-weight: 600;
    padding: 9px;
    width: 50%;
    border: none;
    transition: 0.6s all linear;
}


.register_btn{
    background-color: #F6F6F6;
    color: #3B7C6D;
    border-radius: 16px 16px 0px 0px;
    font-weight: 600;
    padding: 9px;
    width: 50%;
    border: none;
    transition: 0.6s all linear;

}
.active_btn1{
    background-color: #8EF3DB;
    color: #3B7C6D;
    border: 1px solid #2CE0B6;
}

.form_div{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.form_input{
    font-size: 12px;
    width: 100%;
    /* margin-top: 12px; */
    padding: 10px;
    border: 1px solid #A0AAB2;
    border-radius: 12px !important;
}

.submit_btn{
    border-radius: 16px;
    margin-top: 25px;
    background-color: #2CE0B6;
    color: #F7FCFF;
    font-weight: 600;
    padding: 9px;
    border: none;
    font-size: 12px;
}
.forgot_password_link{
    text-decoration: none;
    color: #222629;
    margin-top: 9px;
    font-weight: 600;
    font-size: 13px;
}

.forgot_password_link:hover{
    color: #222629;
    margin-top: 9px;
    font-weight: 600;
}

.sign_in_with_google{
    font-size: 13px;
    width: 100%;
    padding: 4px;
    color: #2CE0B6;
    font-weight: 600;
    border: 1px solid #2CE0B6;
    background-color: white;
    border-radius: 12px;
}


.sign_up_with_google{
    width: 100%;
    padding: 2px;
    color: #2CE0B6;
    font-weight: 600;
    border: 1px solid #2CE0B6;
    background-color: white;
    border-radius: 12px;
}
.step3_heading{
    font-family: 'gilroy';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    color: #222629 !important;
    margin-bottom: 7px !important;
}
.step3_desc{
    font-family: 'gilroy';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    text-align: center !important;
    color: #58646C !important;
    margin-bottom: 8px;

}
.recruiter_page_upload_logo_btn{
    background: #2CE0B6;
    border-radius: 24px;
    padding: 5px;
    color: white !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    color: #F7FCFF;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.36);
}

.recruiter_page_upload_logo_btn:active{
    background: #2CE0B6;
    border-radius: 24px;
    padding: 5px;
    color: white !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    color: #F7FCFF;
    outline: none;
    box-shadow: none;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.36);
}

.recruiter_page_change_logo_btn{
    border: 2px solid #2CE0B6;
    border-radius: 24px;
    padding: 5px;
    color: #2CE0B6 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    color: #F7FCFF;

}

.recruiter_page_change_logo_btn:active{
    border: 2px solid #2CE0B6;
    border-radius: 24px;
    padding: 5px;
    color: #2CE0B6 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    color: #F7FCFF;
    outline: none;
    box-shadow: none;

}
.btn_buy_now{
    background-color: #2CE0B6;
    color: white;
    padding: 7px;
    border-radius: 12px;
    font-size: 90% !important;
    /* font-family: "gilroy" !important; */
    width: 90%;
    border: none;
    font-weight: 600;
    letter-spacing: .9px;
}
.card-title-packages{
    font-weight: 500;
    font-size: 28px !important;
    line-height: 39px;
/* identical to box height */

@media screen and (max-width: 992px) {
    .card-title-packages {
        font-weight: 400;
        font-size: 18px ;
        line-height: 25px;
         ;
    }
  }

/* Gray +2 */



color: #000000;
}

.price-details{
    font-size: 12px;
}
.price3-details{
    font-size: 12px;
    color: white;
}
.card-title-packages2{
    font-weight: 500;
    font-size: 28px;
    line-height: 39px;
    color: #F6F6F6;
}
.price_css{
    font-weight: 300 !important;
    font-size: 30px !important;
    line-height: 48px !important;
    text-align: center !important;
    

}
.card3{
    background: linear-gradient(135deg, #000000 43%, #222629 43%, #222629 56%, #000000 56%);
    max-width: 100%; margin: auto; border-radius: 13px; background-color: #F6F6F6; height: 100%;
}
.card3  .phone{
    font-weight: 600 !important;
    font-size: 25px !important;
    line-height: 48px !important;
    text-align: center !important;
    text-decoration: none;
    /* Gray +2 */
    
    color: #F6F6F6 !important;
}
.card3 .email{
    text-decoration: none;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px !important;
    text-align: center !important;
    
    /* Gray +2 */
    
    color: #F6F6F6 !important;
    
}



.footer_ul{
    display: flex;
}

@media (max-width:300px) and (min-width:100px) {
    
    .banner-col{
        position: relative;
        width: 100%;
        /* padding: 0px 100px; */
        height: 250px !important;
        background-repeat: no-repeat;
        background-size: 90% ;
        background-position: 45% 5%;
    }
    .log_form{
   
        position: relative;
        width: 100%;
        max-width: 450px;
        height: 430px;
        border-radius: 16px;
        background-color: white;
        border: 1px solid #E5E5E5;;
        left: 0%;
        top: 21%;
        padding: 16px;
        transition: 0.7s;
        overflow-x: hidden !important; 
        overflow-y: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none; 
    }
}

@media (max-width:600px) and (min-width:301px) {
    
    .banner-col{
        position: relative;
        width: 100%;
        /* padding: 0px 100px; */
        height: 250px !important;
        background-repeat: no-repeat;
        background-size: 90% ;
        background-position: 45% 5%;
    }
    .log_form{
   
        position: relative;
        width: 100%;
        max-width: 450px;
        height: 430px;
        border-radius: 16px;
        background-color: white;
        border: 1px solid #E5E5E5;;
        left: 0%;
        top: 30%;
        padding: 16px;
        transition: 0.7s;
        overflow-x: hidden !important; 
        overflow-y: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none; 
    }
}
