
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

.milestone{
    background: #4893B3;
    padding: 50px auto;
}
.milestone h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    /* identical to box height */
    color: #FFFFFF;

}

.contact-container{
    margin: auto;
    width: 50%;
    padding: 10px;
    align-items: center;
    border-radius: 0;
    /* position: absolute; */
    left: 50%;
    top: 50%;
    /* transform: translate(-50%,-50%); */
    .row{
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
       
    }
    
}

.col-md-7{
    padding: 20px;
}
.col-md-5{
    background: #4893B3;
    padding: 20px;
    color: white;

}
.form-control {
    /* height: 42px; */
    background: #fff;
    color: #000;
    font-size: 14px;
    border-radius: 2px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.contact-submit-button{
    background: #4893B3;
    border-radius: 12px;
    height: 32px;
    border: none;
    padding: 4px;
    width: 100%;
    font-size: 12px;
}
.bi{
    font-size: 20px;
}
.d-flex p{
    font-size: 12px;
    padding-left: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.form-label{
    font-weight: 500;
}


@media only screen and (max-width:600px){
    .contact-container{
        width: 100%;
        /* position: absolute; */
        left: 50%;
        top: 90%;
    }
}