*{
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}


/* .logo_img{
    max-width: 160px; 
    height: auto; 
    object-fit: cover;
} */
.button1{
    font-size: 0.9rem;
    margin-right: 26px; 
    border-radius: 15px; 
    border: 1px solid #3B697C; 
    color: #3B697C; 
    font-weight: 600;
}
.button1:hover{
    color: white;
    background-color: #3B697C;
}
.button1:focus{
    background-color: #BBE6F9;
    color: white;
    outline: none !important;
    box-shadow: none;
}

.button2{
    font-size: 0.9rem;
    margin-right: 18px; 
    border: 1px solid #41A991; 
    color: #41A991; 
    font-weight: 600;
    border-radius: 15px;
}

.button2:hover{
    color: white;
    background-color: #3B7C6D;
}
.button2:focus{
    background-color: #BBF9EA;
    color: white;
    outline: none !important;
    box-shadow: none;
}



.button3:focus{
  
    outline: none !important;
    box-shadow: none;
}

.row h2{
    font-size: 25px;
}

.numbers_size{
    font-weight: 700; 
    /* color: #3B697C;  */
    color: white;
    font-size: 25px;
}
.description{
    font-size: 20px; 
    /* color: #3B697C; */
    /* color: #3B697C; */
    color: white;
    margin-left: 12px;
}

.mb-4{
    text-align: center;
    font-size: 30px;
    font-style: normal !important;
    font-weight: 600 !important;
}

.card-img-top{
    height: 200px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.card-title{
    font-style: normal !important;
    font-weight: 600 !important;
}

.card-text{
    font-style: normal !important;
    font-weight: 500 !important;
    color: #5e5e5e;
    line-height: 24px;
    margin-top: 10px;
}



.card{
    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.271);
}



.footer_ul{
    display: flex;
}

@media (max-width: 400px) and (min-width: 100px) {
    .numbers_size{
       font-size: 16px;
    }
    
    .description{
        font-size: 10px; 
        margin-left: 4px;
    }
    .footer_ul{
        text-decoration: none;
        list-style-type:none !important;
        flex-direction: column;
    }
    .slider{
        margin-top: 15px;
    }

  

    #button3{
        margin-top: 25px;
        margin-left: -12px;
    }


    /* footer */

    .footer_div2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 18px !important;
    }
    .text-end{
        margin-top: 10px;
    }
    .d-inline{
        height: 40px;
    }
    .d-block{
        padding: 0px;
        margin-top: 8px;
    }
    
}



@media (max-width: 600px) and (min-width: 401px) {
  
    .footer_ul{
        text-decoration: none;
        list-style-type:none !important;
        flex-direction: column;
    }
    .numbers_size{
        font-size: 14px;
     }
     
     .description{
         font-size: 10px; 
         margin-left: 2px;
     }
     .footer_css{
        padding: 20px 0px;
     }
     .d-inline{
        height: 40px;
    }
    .d-md-flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
    }

    .btn{
        font-size: 11px;
    }
}
.left-img{
    box-sizing: border-box;
    background: linear-gradient(to bottom, white 50%, #3B697C 50%);
    background-size: 100% 200%;
    background-position: left -27px;
    transition: .5s ease-out;
    cursor: pointer;
}

.right-img{
    box-sizing: border-box;
    background: linear-gradient(to bottom, white 50%, #3B7C6D 50%);
    background-size: 100% 200%;
    background-position: left -27px;
    transition: .5s ease-out;
    cursor: pointer;
}

.left-img:hover,.right-img:hover{
    background-position: bottom;
}
.left-img h2{

display: flex;
max-width: 100%;
width: fit-content;
margin: auto;
height: 30%;
align-items: center;
align-self: center;
justify-content: center;
font-weight: 700;
color: #4893B3;
transition: 0.2s all linear;

}
.right-img h2{

display: flex;
max-width: 100%;
width: fit-content;
margin: auto;
height: 30%;
align-items: center;
align-self: center;
justify-content: center;
font-weight: 700;
/* font-size: 35px; */
/* line-height: 58px; */
color: #3B7C6D;
transition: 0.2s all linear;

}

.left-img:hover h2{
    transition-delay: 0.3s;
    transform: scale(1.2);
    color: #4893B3;
}
.right-img:hover h2 {
    transition-delay: 0.3s;
    transform: scale(1.2);
    color: #3B7C6D;
}

.subscribe-btn{
background-color: #4893B3 !important;
display: none;
}

.ellipsis {
text-overflow: ellipsis;
white-space: nowrap;
display: block;
overflow: hidden;
width: 20em;
}