img{
    /* mix-blend-mode: multiply; */
}
.sonia-card{
    display: flex;
    border-radius: 12px;
    padding: 18px;
    box-shadow:0px 0px 10px 0px rgba(82, 63, 105, 0.1);;
   
}
.des3{
    padding-left: 15px;
    font-size: 12px;
}
.paragraph{
    color: gray;
}
.description2{
    color: gray;
}
.board{
    text-align: center;
    font-size: 33px;
    font-weight: 300;
    /* padding-b3ttom: 12px; */
    /* padding-top: 12px; */
}
.our-team{
  padding: 0 10px;
  text-align: center;
  position: relative;
    }
    .our-team .pic{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto 25px;
  overflow: hidden;
    }
    .our-team .pic img{
  width: 100%;
  height: auto;
    }
   
    .our-team .title{
  display: inline-block;
  font-size: 19px;
  color: #001d33;
  letter-spacing: 2px;
  margin: 0 0 15px 0;
  text-transform: uppercase;
    }
    .our-team .post{
  display: inline-block;
  font-size: 12px;
  color: #1c5acc;
  text-transform: uppercase;
    }
    .our-team .description{
  font-size: 10px;
  color: #777;
  line-height: 17px;
    }
    @media only screen and (max-width: 990px){
  .our-team{ margin-bottom: 30px; }
    }