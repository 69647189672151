
 body {
  height: 100%;
  width: 100%;

}

.ck-editor__editable_inline {
  min-height: 150px;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 24px;
  /* margin: 30px 0 -10px; */
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}

.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
  outline: none !important;
}

.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}

.modal-confirm .btn-danger {
  background: #f15e5e;
}
.modal-confirm .btn-danger:hover, .modal-confirm .btn-danger:focus {
  background: #ee3535;
}

.modal-confirm .btn-info {
  background: #c1c1c1;
}
.modal-confirm .btn-info:hover, .modal-confirm .btn-info:focus {
  background: #a8a8a8;
}

.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 25px;
}

.pwd-container2 img {
  margin-right: 10px;
  margin-top: -30px;
  float: right;
  cursor: pointer;
  width: 20px;
}

@media only screen and (max-width:900px) {
  .jobseeker-profile-container{
    margin-top: 24px;
    /* line-height: 32px; */
    display: flex;
    flex-direction: column;
    margin-left: 10% !important;
    margin-right: 10% !important;
    font-family: 'Poppins', sans-serif;

}

.prfile-card{
  width: 100%;
  font-size: 12px !important;
}

.card-head-line{
  font-size: 15px !important;
}
.btnpf5{
  padding: 6px 10px 6px 10px !important;
  font-size: 10px;
}
}


/* Loader Screen  */
.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
