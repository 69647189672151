*{
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}
.withBorderBottom {
    border-bottom: 1px solid black;
  }

/* .logo_img{
    max-width: 160px; 
    height: auto; 
    object-fit: cover;
} */
.button1{
    margin-right: 10px; 
    border-radius: 15px; 
    border: 1px solid #3B697C; 
    color: #3B697C; 
    font-weight:600;
}

.button1:hover{
    color: #3B697C;
    background-color: #BBE6F9;
}
.button1:focus{
    background-color: #BBE6F9;
    color: white;
    outline: none !important;
    box-shadow: none;
}

.button2{
    margin-right: 10px; 
    border-radius: 15px; 
    border: 1px solid #41A991; 
    color: #41A991; 
    font-weight: 600;
}

.button2:hover{
    color: white;
    background-color: #3B7C6D;
}

.button2:focus{
    background-color: #BBF9EA;
    color: white;
    outline: none !important;
    box-shadow: none;
}


.button3:focus{
    outline: none !important;
    box-shadow: none;
}

.text-center{
    /* font-family: "gilroy" !important; */
    font-weight: 500 ;
    font-size: 14px ;
}

.slide-track{
    margin-top: 20px;
}

.mb-4{
    text-align: center;
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight: 600 !important;
}



.banner-col{
    position: relative;
    width: 100%;
    /* height: 430px; */
}

.banner-col form {
    position: absolute;
    top: 50%;
    left: 40%;
}


.two_input{
    /* position: absolute; */
    background-color: #FFFF;
    width: 370px !important;
    border-radius: 35px;
    padding: 12px 32px;
    /* top: 70%; */
    z-index: 2;
    /* left: 480px; */
}

.city_style{
    /* font-family: "gilroy"; */
    color: black;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 1px;
}

.card{
    max-width: '100%';
     margin: 'auto'; 
     border-radius: 122;
      background-color: '#BBE6F9'; 
      height: '100%'; 
      border:'none';
    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.271);
}

.job-category-card-img-top{
    height: 200px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.job-category-card-title{
    /* font-family: "gilroy"; */
    font-weight: 600;
    text-transform: capitalize
}

.pd{
    padding: 2rem 1rem;
}

.job-category-card-text{
    /* font-family: "gilroy"; */
    font-weight: 500;
    color: #5e5e5e;
    line-height: 24px;
    margin-top: 10px;
}

.job_type_style{
    color: black;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 1px;
}
.input1{
    border: none !important;
    font-size: 12px !important;
    max-width: 100% !important;
   border-bottom: 1px solid black !important;
    padding: 3px !important;
}

.input1:focus{
   box-shadow: none !important;
   border-bottom: 1px solid black !important;
   outline: none !important;

}
.input2{
    border: none !important;
    font-size: 12px !important;
    width: 100% !important;
    padding: 3px !important;
    border-bottom: 1px solid black !important;
}

.input2:focus{
    box-shadow: none !important;
    
    outline: none !important;
 
 }

.numbers_size{
    font-weight: 700; color: #FFFFFF; font-size: 25px;
}

.description{
    font-size: 20px; 
    color: #FFFFFF;
    margin-left: 12px;
    /* font-family: "gilroy"; */
    font-weight: 600;
}

.cord{
    
    background-color: white !important;
    border-radius: 10px;
    color: white;
    padding: 30px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .career{
    padding: 2rem 1rem !important;
}

.careertext{
    margin-top: 15px !important;
} */

.thirdp{
    display: initial !important;
}

.oneline{
    margin-top: 18px !important;
}

.job_category_title{
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 29px !important;
    /* text-align: center !important; */
    color: black !important;
}
.job_category_desc{
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    /* text-align: center !important; */
    color: rgb(66, 66, 66) !important;
}
.filter_div{
   
    background: #F6F6F6;
    /* Gray +1 */

    border: 1px solid #DADEE0;
    border-radius: 16px;
}
.filter_heading{
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    padding: 7px;
}

.salary{
    margin-bottom: 6px !important;
}

.filter_tags{
    background-color: white;
    border-radius: 16px;
    border: none;
    margin-top: -6px;
    padding: 8px;
}
.job_category_input{
    background-color: white;
    border-radius: 16px;
    border: none;
    margin-top: 22px;
}
.experience_div{
    margin-top: 30px;
    padding: 5px 9px;
}
.salary_div{
    margin-top: 40px;
    padding: 5px 9px;   
}
.job_type_tags{
    background-color: white;
    border-radius: 16px;
    border: none;
    margin-top: 10px;
    margin-bottom: 20px;
}




.footer_ul{
    display: flex;
}

@media (max-width: 400px) and (min-width: 100px) {
    .numbers_size{
        font-size: 10px;
     }
     
     .description{
         font-size: 9px; 
         margin-left: 4px;
     }

     .p-sm-4{
        padding: 8px 0px;
     }

    .footer_ul{
        text-decoration: none;
        list-style-type:none !important;
        flex-direction: column;
    }
    
    .banner-col{
        height: 230px!important;
    }
    .banner1{
        left: 0%;
        width: 100%;
        height: auto;
    }
    
    .two_input{
       
        width: 100%;
        padding: 25px 16px;
        top: 120px;
        left: 0px;
    }

    #button3{
       
        margin-left: -12px;
    }

   

    #search svg{
        height: 20px !important;
        margin:0px !important;
    }

    .rounded-circle{
        height: 40px !important;
        width: 40px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer_div2 {
        /* display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 18px !important; */
    }

    .text-end{
        margin-top: 10px;
    }

    .d-inline{
        height: 40px;
    }

    .d-block{
        padding: 0px;
        margin-top: 8px;
    }


    .allfilters{
        padding:4px !important;
        display: flex;
        align-items: center !important;
    }

    .result{
        font-size:14px !important;
    }

    #sort_by{
        padding: 0px;
        font-size: 14px !important;
    }

    .sortby_text{
        font-size: 15px;
    }

    .h5{
        font-size: 15px !important;
    }
 
    .text-muted{
        font-size: 12px;
    }

    .features{
        padding: 0px;
    }

    #feature{
        display: initial !important;
    }
}


@media (max-width: 600px) and (min-width: 401px) {
  
    .footer_ul{
        text-decoration: none;
        list-style-type:none !important;
        flex-direction: column;
    }
    .banner-col{
        height: 250px !important;
    }
    .banner1{
        left: 0%;
        width: 100%;
        height: auto;
    }
    .two_input{
       
        width: 100%;
        padding: 12px 32px;
        top: 160px;
        left: 0px;
        margin-top: 20px;
    }

    .numbers_size{
        font-size: 14px;
     }
     
     .description{
         font-size: 10px; 
         margin-left: 2px;
     }

     .p-sm-4{
        padding: 12px;
     }

     .footer_css{
        padding: 20px 0px;
     }
     .d-inline{
        height: 40px;
    }
    .d-md-flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
    }
    .allfilters{
        padding:4px !important;
        display: flex;
        align-items: center !important;
    }

    .result{
        font-size:14px !important;
    }

    #sort_by{
        padding: 0px;
        font-size: 14px !important;
    }

    .sortby_text{
        font-size: 15px;
    }

    .h5{
        font-size: 18px !important;
        line-height: 24px;
    }
 
    .text-muted{
        font-size: 12px;
    }

    .features{
        padding: 0px;
    }

    #feature{
        display: initial !important;
    }

    #filter{
        padding: 0px !important;
    }

    .card{
        margin-bottom: 26px !important;
    }
}


@media (max-width: 650px) and (min-width: 601px) {
  
    .banner-col{
        height: 180px;
    }
    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }

    .searchp{
        position: initial;
        margin-top: 180px !important;
        margin-left: -20px;
    }
    
}

@media (max-width: 750px) and (min-width: 651px) {
  

    .banner-col{
        height: 180px;
    }
    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }
    .two_input{
       
        width: 330px;
        padding: 8px 32px;
        top: 90px;
        left: 270px;
    }

   

}



@media (max-width: 850px) and (min-width: 751px) {
  
    .banner-col{
        height: 200px;
    }
    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }
    .two_input{
       
        width: 350px;
        padding: 8px 32px;
        top: 110px;
        left: 320px;
    }
}


@media (max-width: 950px) and (min-width: 851px) {
  
    .banner-col{
        height: 220px;
    }
    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }
    .two_input{
       
        width: 380px;
        padding: 8px 32px;
        top: 130px;
        left: 360px;
    }
}


@media (max-width: 1100px) and (min-width: 951px) {
    .banner-col{
        height: 260px;
    }

    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }
    .two_input{
       
        width: 470px;
        padding: 12px 32px;
        top: 140px;
        left: 400px;
    }
}


@media (max-width: 1200px) and (min-width: 1101px) {
  
    .banner-col{
        height: 310px;
    }
    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }
    .two_input{
       
        width: 530px;
        padding: 12px 32px;
        top: 150px;
        left: 450px;
    }
}


@media (max-width: 1300px) and (min-width: 1201px) {
  
    .banner-col{
        height: 340px;
    }
    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }
    .two_input{
       
        width: 590px;
        padding: 12px 32px;
        top: 160px;
        left: 500px;
    }
}



@media (max-width: 1400px) and (min-width: 1301px) {
  

    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }
    .two_input{
       
        width: 650px;
        padding: 12px 32px;
        top: 175px;
        left: 540px;
    }
}


@media (max-width: 1500px) and (min-width: 1401px) {
  

    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }
    .two_input{
       
        width: 700px;
        padding: 12px 32px;
        top: 185px;
        left: 590px;
    }
}



/* @media (max-width: 1600px) and (min-width: 1501px) {
  

    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }
    .two_input{
       
        width: 730px;
        padding: 12px 32px;
        top: 200px;
        left: 630px;
    }
} */



@media (max-width: 88000px) and (min-width: 1601px) {
  

    .banner1{
        left: 5%;
        width: 90%;
        height: auto;
    }
    .two_input{
       
        width: 780px;
        padding: 12px 32px;
        top: 220px;
        left: 670px;
    }
}


.subscribe-btn{
    background-color: #4893B3 !important;
    display: none;
}
