/* Admin Pannel Css ---> Search here 🔍Admin Pannel  */
:root{
    --primary-font-family: 'Poppins', sans-serif;
    --light-white:#f5f8fa;
    --gray:#5e6278;
    --gray-1:#e3e3e3;
}
body{
    font-size: 14px;
    height: 100vh;
    width: 100%;
} 
.wrapper input{
    border-radius: 12px;
    background-color: var(--light-white);
    border-color: var(--light-white);
    color: var(--gray); 

}
.wrapper input:focus{ 
    box-shadow: none;
}
.wrapper .submit_btn_signIn{
    border: none;
    background-color:#57b2d9;
    padding:12px;
    font-weight: 500;
}
.wrapper .submit_btn_signInRecruiter{
    border: none;
    background-color:#67baa7;
    padding:12px;
    font-weight: 500;
}
.wrapper .login_with{
    padding:12px;
    font-size: 15px;
    font-weight: 500;
    transition:0.3s ease-in-out;
    /* border-radius: 22px; */

}
.wrapper .submit_btn_signIn:focus,
.wrapper .login_with:focus{
    box-shadow: none;
}
.wrapper .login_with:hover{
    background-color:var(--gray-1);
    border-color:var(--gray-1);
}
.wrapper .login_with img{
    max-width: 8%;
}

.rec-banner1{
    width:95vw;
    height: 50vh;
    object-fit: fill;
    

}
.img-container {
    position: relative;
    height: 400px; /* Adjust as needed */
  }
  
  .img1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 1600px) and (max-width: 3370px) {
    .img1 {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  
  .text-overlay {
    position: absolute;
    top: -20px; 
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    padding: 10px;
    text-align: center;
    width: 100%;
  }
  
  
/* Main CSS */ 
.wrapper{
    /* margin-top: 3%; */
    padding:0 0 100px;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-attachment: fixed;
    background-size:contain;
    /* min-height: 78vh; */
    min-height: calc(100vh - 48px - 92px);

}
a{
    text-decoration: none;
}
.dropdown-menu {
    position: absolute;
    /* left: -92px !important; */
    top: 100%;
    margin-top: 5px; 
  }


  /* -------------------------- Navbar and Footer -------------------------- */


  .logo_img{
    max-width: 160px; 
    height: auto; 
    object-fit: cover;
    width: 135px !important;
}

.lock-nav{
    margin-left: 5px !important;
    margin-right: 5px !important;
    img{
        width: 22px;
    }
 }
.button1{
    font-size: 0.9rem;
    margin-right: 26px; 
    border-radius: 15px; 
    border: 1px solid #3B697C; 
    color: #3B697C; 
    font-weight: 600;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
.button1:hover{
    color: white;
    background-color: #3B697C;
}
.button1:focus{
    background-color: #BBE6F9;
    color: white;
    outline: none !important;
    box-shadow: none;
}



.button2{
    font-size: 0.9rem;
    margin-right: 18px; 
    border-radius: 15px;
    border: 1px solid #41A991; 
    color: #41A991; 
    font-weight: 600;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.button2:hover{
    color: white;
    background-color: #3B7C6D;
}
.button2:focus{
    background-color: #BBF9EA;
    color: white;
    outline: none !important;
    box-shadow: none;
}

.button3{
    padding: 0px;
}

.button3:focus{
    outline: none !important;
    box-shadow: none;
}
.sign_out_btn{
    border: 1px solid #4893B3;
    border-radius: 24px;
    padding: 8px 10px;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #4893B3 !important;
}
.sign_out_btn:hover{
   
    background-color: #4893B3;
    padding: 8px 10px;
   
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    color: white !important;
    outline: none;
    box-shadow: none;
}

.sign_out_btn2{
    /* background: #8EF3DB; */
    border: 1px solid #2CE0B6;
    border-radius: 24px;
    padding: 5px 10px;
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #2CE0B6 !important;
}
.sign_out_btn2:hover{
   
    background-color: #2CE0B6;
    padding: 8px 10px;
   
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    color: white !important;
    outline: none;
    box-shadow: none;
}
.sign_out_btn:focus{
   
    background-color: #4893B3;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    outline: none;
    box-shadow: none;
}

.logo_img_footer{
    max-width: 180px; 
    height: auto; 
    object-fit: cover;
}
.copyright{
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-decoration: none; 
    color:white;
}


.footer_ul li{

    margin-right: 15px !important; 
    font-size: 14px !important;
    display:inline !important;
}
.footer_ul li a{
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-decoration: none; 
    color:white;
}


.footer_div2 li{

    margin-right: 15px !important; 
    font-size: 14px !important;
    display:inline !important;
}
.footer_div2 li a{
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-decoration: none; 
    color:white;
}

/* .footer_css{
    background-color:#4893B3; 
    color: #FFFFFF; 
    margin-top: 10px; 
    padding:10px 40px;
} */
@media (max-width: 400px) and (min-width: 100px) {
    
    .footer_css{
        background-color:#4893B3; 
        color: #FFFFFF; 
        margin-top: 40px; 
        padding:20px 20px;
    }
    .numbers_size{
       font-size: 20px;
    }
    
    .description{
        font-size: 15px; 
    }
   
}
.footer-icons{
    height: 24px;
    aspect-ratio: 1;
    color: #bbe6f9;
    margin-right: 2px;
}


/* @media (min-width: 2560px) and (max-width: 2758px) {
    .footer_css{
        background-color:#4893B3; 
        color: #FFFFFF; 
        margin-top: 270px; 
        padding:20px 20px;
    }
} */


/*--------------------------  / JobSeekerProfile \  ----------------------  */
.jobseeker-profile-container{
    margin-top: 24px;
    /* line-height: 32px; */
    display: flex;
    flex-direction: column;
    margin-left: 25%;
    margin-right: 25%;
    font-family: 'Poppins', sans-serif;


}
.applicant-profile-container{
    margin-top: 24px;
    /* line-height: 32px; */
    /* display: flex; */
    flex-direction: column;
    margin-left: 15%;
    margin-right: 15%;
    font-family: 'Poppins', sans-serif;


}
.card-head-line{
    font-weight: 550;
}
    
.profile-img{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px !important;
    width: 200px !important;
    border-radius: 50%;
    margin: 0 auto; 
    overflow: hidden; 
    cursor: pointer;
    
}
.profile-imgloader{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 200px; */
    margin: 0 auto; 
    overflow: hidden; 
    cursor: pointer;
}
.prfile-card{
    background-color: white;
    padding: 24px;
    padding-bottom: 24px;
    border-radius: 12px;
    border: 2px solid rgba(128, 128, 128, 0.125);
    width: 100%;

    
}
.btn-profilecard{
    /* display: flex; */
    justify-content: space-between;
    width: 100%;
}
.btnpf1{
    margin-right: 10px;
    margin-left: 10px;
    background-color: #66d1ff;
    border: none;
    width: auto;
}
.btnpf4{
    /* margin-right: 10px; */
    /* margin-left: 10px; */
    background-color:transparent;
    color: rgb(73, 71, 71);
    border: none;
    width: auto;
}
.btnpf5{
    margin-right: 10px !important;
    margin-left: 10px !important;
    background-color:transparent !important;
    color: rgb(73, 71, 71) !important;
    border-radius: 12px !important;
    border: 1px solid rgba(128, 128, 128, 0.385) !important;
    padding: 8px 16px 8px 16px ;
    width: auto !important;
    margin-top: 5px !important;
}
.prof-details{
    font-family: 'Poppins', sans-serif;
    /* line-height: 22px; */
    /* margin-left: 12px; */
    /* margin-top: 16px; */
    color: rgb(84, 83, 83);
}
.prof-details {
        font-weight: 600;
        font-family: 'Poppins', sans-serif;

}
.view-profile-link{
    color: white;
  text-decoration: none;
}

/*--------------------------  / Edit Profile \  ----------------------  */

.change_btn{
    border: 1px solid #41A991;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
     background: white;
    color: #4893B3;
    border-radius: 24px;
    display: flex;
    align-items: center;
    align-self: auto;
}

.cancel_btn{
    border: 2px solid #41A991;
    border-radius: 24px;
    padding: 5px 30px;
    margin-right: 12px;
    /* font-style: normal; */
    font-weight: 600;
    font-size: 18px;
    /* line-height: 36px; */
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    background: white;
    /* Green -1 */

    color: #41A991;
}

.save_btn{
    border: none;
    background: #2CE0B6;
    border-radius: 24px;
    padding: 5px 30px;
    /* width: 100px; */
    
    /* font-style: normal; */
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #F7FCFF;

    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.36);
}

/*--------------------------  / featured-job \  ----------------------  */
.hero-job{
    padding-left:20% ;
    padding-right:20% ;
    padding: 10px;
    background-color:#4893B3 ;
    color: white;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.back-arrow{
    margin-left: 10%;
    /* width: fit-conte; */
    width: 15%;
}
.apply_now_btn{
    /* Blue -1 */

    border: none !important;
    background: #5FC2EC!important;

    border-radius: 17px!important;
    padding: 6px!important;
    
    font-weight: 600!important;
    font-size: 20px!important;
    line-height: 36px!important;
    width: 100%!important;
    /* margin-top: 18px; */
    color: white!important;
    /* text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.36)!important; */
}
.share-btn{
    background-color: #19bba3;
    border: none;
    font-size: 12px;
    font-weight: 600;
    height: 28px;
    margin-bottom: 12px;
    border-radius: 12px;
}
.save_btn1{
    /* Blue */

    border: 1px solid #4893B3 !important;
    background: white !important;
    border-radius: 17px;
    padding: 6px !important;
   
    font-weight: 600;
    font-size: 20px !important;
    line-height: 36px !important;
    width: 100% !important;
    margin-top: 18px !important;
    color: #4893B3 !important;
    /* text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.36)!important; */
    
}
.featured-job-container{
    margin-left: 10%;
    margin-right: 10%;
    margin-top:1% ;
    
}
.filter-box{
    display: flex;
    justify-content: space-between;
    font-size: 90%;
    height:min-content;

}

.search-minihead{
    font-weight: 500 !important;
}

.form-select{
    border-radius: 0.3rem !important;
    font-size: 12px;
    min-height: 38px;
    
}

.input-search{
    padding: 6px;
    border-radius: 0.3rem;
    border: 1px solid rgba(128, 128, 128, 0.414);
    font-size: 12px;
    width: 100%;
    margin-right: 4px;
    margin-top: 6px;
    min-height: 38px;
    margin-bottom: 12px;

}
.sec-box{
    display: flex;
    justify-content: space-between;
    font-size: 80%;   
    
}

.label-head{
    margin-bottom: 12px;
}
.dropdown-head{
    margin-left: 12px;
    margin-right: 12px;
}
.sec-box{
    .two-in-one{
        /* background-color: #66d1ff; */
        margin-right: 12px;
        color: black;
        font-weight: 400;
        font-size: 12px;
    }
}

.rounded-pill{
    font-size: 16px;
    min-height: 42px;
    background: #66d1ff;
    color: white;
}

.normal-button{
    border-radius: 35px;
    font-size: 16px;
    min-height: 42px;
    background: white;
    color: black;
    border: 1px solid rgba(128, 128, 128, 0.337);
}
/* ----Job List --- */
.joblist-card{
    border-bottom: 1px solid rgba(128, 128, 128, 0.337);
}
.job-card{
    font-size: 16px;
    font-weight: 600;
}
.company-name{
    font-size: 12px;
    color: gray;
}
.job-type{

    font-size: 10px;
    color: gray;
}

.job-details{
    font: 10px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}
.card-buttom{
    display: flex;
    justify-content: space-between;
    
}
.card-buttom1{
    background-color: #bbe6f9;
    border: none;
    font-size: 12px;
    height: 28px;
    border-radius: 12px;
    margin-bottom: 12px;
    color: rgb(0, 0, 0);
    font-weight: 400;
}
.card-buttom2{
    background-color: #66d1ff00;;
    color:#66d1ff ;
    border-color: #66d1ff;
    font-size: 12px;
    font-weight: 600;
    height: 28px;
    margin-bottom: 12px;
    border-radius: 12px;
}

/*--------------------------  / Job-Description \  ----------------------  */
.job-description-details{
    font-size: 12px;
}
.details-head{
    font-weight: 600;
}
.job-minicard{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight:470;
}
.job-minicard-des{
    color: gray;
}


/*--------------------------  / Applied Job \  ----------------------  */

.appliedjob-container{
    margin-left: 15%;
    margin-right: 15%; 
}

.active-tabs{
    background: white;
    border-bottom: 1px solid transparent;
}

.active-tabs::before{
    content: "";
    display: block;
    position: absolute;
    top:-5px;
    left: 50%;
    transform: translate(-50%);
    width: calc(100% +2px);
    height: 5px;
    background: rgb(88,147,241);
}

.active-content{
    display: block;
}


/*--------------------------  / Employer Dasboard \  ----------------------  */
.sidebars{
    display: flex;
    flex-direction: column;
}

.das-sidenav{
    margin-bottom: 12px;
    color: black;
    text-decoration: none;
    width: 80%;
    padding: 10px;
    padding-left: 20px;
    border-radius: 4px;
    /* font-size: 12px; */
}

.das-sidenav-active{
    margin-bottom: 12px;
    text-decoration: none;
    width: 80%;
    padding: 10px;
    padding-left: 20px;
    border-radius: 4px;
    background-color: rgb(211, 255, 245);
    color: green;
}
.das-card{
    height: 25vh;
    margin-right: 16px;
}
.purchased-card-container{
display: grid;
grid-template-columns: auto auto;
column-gap: 42px;
font-family: 'Poppins', sans-serif;

}

.das-card2{
    border-radius: 4px;
    width: 100%;
    /* height: 25vh; */
    margin-right: 16px;
    
}
.purchase-details{
    padding-left: 12px;
}

.sidebars{
    /* margin-left: 42px; */
    height: 70vh;
}
.no-sub-msg{
    border-radius: 4px;
    padding: 10px; 
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    border: 1px solid rgb(0, 128, 255);
    background-color: #b5e2f64c;
    margin-right: 15px;
    font-family: 'Poppins', sans-serif;

}
.buy-btn{
    border: none;
    /* height: fit-content; */
    background-color:transparent ;
    color: rgb(0, 128, 255);
    margin-right: 16px;
}
.msg{
    margin-top: 6px;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 400;
}
.font-size-no{
    font-size: 64px;
    font-weight: 600;
    color: #939393;
}


/*--------------------------  / Folder \  ----------------------  */

.job_btn{
    background: #2CE0B6;
    border-radius: 24px;
    border: none;
    padding: 9px 40px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 36px;
    color: white;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.36);
}

/* -------- Table------- */
.dataTables_length{
    display: flex;
    justify-content: space-between;
}
.search-table{
    border-radius: 12px;
    padding: 2px;
    border-color:  rgba(128, 128, 128, 0.234);
}
.table-headline{
    background-color: #8ef3db;
    font-weight: 100;
}
.sorting{
 font-weight: 500;
}

/* table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
  bottom: .5em;
} */

/* ---------- About Us ---------- */
.sonia-img{
    width: 150px;
    height: 150px;
}





/* -------------- Admin Pannel --------------- */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100&display=swap');

.admin-sidenav{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.admin-navlink{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: gray;
    padding: 10px;
    margin-bottom: 12px;
    font-weight: 400;
}
/* ----- Selected NavLink ----- */

.admin-navlink-active{
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    color: rgb(255, 255, 255) !important;
    padding: 10px !important;
    margin-bottom: 12px !important;
    font-weight: 400 !important;
    background-color: #58be2e !important;
}

/* ----- Selected NavLink ----- */
/* .admin-navlink:hover{
    color:#58be2e !important;
} */
.admin-navlink-active:hover{
    color:#ffffff !important;
}

.admin-nav{
    border-bottom: 1px solid #E6EEF0;
}

.logo-name{
    width: 16%;
    position: relative;
    bottom: -2px;
}
.avtar-header{
    width: 32px;
    height: 32px;
    border-radius: 100%;
}
.nav-content{
    /* height: 100vh; */
    box-shadow: 12px 12px 12px  solid black;
    border-right:1px solid #E6EEF0;


}

.main-content{
    background-color: #F9FAFB;
    /* height:calc(100vh-48px) */
    height:100vh
}
.main-content-child{
    padding: 16px;
}
/* ------Admin Dashboard --------- */

.dashcard-text{
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: gray;

}
.dash-card{
    /* border:1px solid red; */
    /* margin-right: 20px; */
    height: 230px;
    width: 230px;
}

.headline{
    font-family: 'Poppins', sans-serif;
    /* margin-left: 12px; */
    padding: 16px;
    font-weight: 600;
}

/* --------- Job seeker List --------- */

.unblock-btn{
    border-radius: 4px;
    color: white !important;
    /* height: 32px; */
    padding: 6px 10px;
    font-size: 12px;
    align-items: center;
    background-color: #19bba3 !important;
    margin: 4px;
    border: none;
}
.block-btn{
    border-radius: 4px;
    color: white !important;
    /* height: 32px; */
    padding: 6px 10px;
    font-size: 12px;
    align-items: center;
    background-color: #fe5678 !important;
    margin: 4px;
    border: none;
}
.view-btn{
    border-radius: 4px;
    color: white !important;
    /* height: 32px; */
    padding: 6px 10px;
    font-size: 12px;
    align-items: center;
    background-color: #58be2e !important;
    margin: 4px;
    border: none;
}
.view-app-btn{
    border-radius: 4px;
    /* color: white !important; */
    /* height: 32px; */
    padding: 6px 10px;
    font-size: 12px;
    align-items: center;
    background-color: #ffc75f !important;
    color:white;
    margin: 4px;
    border: none;
}

.export-btn{
    padding: 12px;
    background-color: rgb(0, 166, 255);
    border: none;
    color: white;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 12px;
}
.search-list{
    height:34px;
    margin: 12px;
    padding: 12px;
    border: none;
    font-family: 'Poppins', sans-serif;
    border-radius: 6px;
    border: 1px solid rgba(128, 128, 128, 0.307);
}

/* ------ Degree ------ */
.edit-btn{
    border-radius: 4px;
    color: white !important;
    /* height: 32px; */
    padding: 6px 10px;
    font-size: 12px;
    align-items: center;
    background-color: rgb(10, 222, 190) !important;
    margin: 4px;
    border: none;
}
.delete-btn{
    padding: 6px 12px;
    border-radius: 4px;
    color: white !important;
    /* height: 32px; */
    font-size: 12px;
    align-items: center;
    background-color: #fe5678 !important;
    margin: 4px;
    border: none;

}

.add-btn{
    padding: 12px 18px 12px 18px ;
    background-color:  rgb(10, 222, 190) !important;
    border: none;
    color: white;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 12px;
}
.approve-btn{
    border-radius: 4px;
    color: white !important;
    /* height: 32px; */
    padding: 6px 10px;
    font-size: 12px;
    align-items: center;
    background-color: #6a6a6a7c !important;
    margin: 4px;
    border: none;
}
.show-btn {
    padding: 0.3px 6px 1px 6px; 
    border-radius: 50%; 
    background-color: rgb(10, 222, 190); 
    color: white; 
    text-align: center; 
    font-weight: 600;
    display: inline-block; 
    text-decoration: none; 
    border: none;
}

.upload-btn{
    background-color:  rgb(10, 222, 190) !important;
    border: none;
    color: white;
    padding: 6px;
    border-radius: 12px;
    font-family: 'Poppins', sans-serif;
}
/* ------------- BlueStrip ----------- */
.blue-strip-container {
    margin-top: 30px;
    background: #4893b3;
  }
.custom-section {
    margin-top: 30px;
  }
  
  .custom-row {
    background: #4893b3;
  }
  
  .custom-col {
    border-right: 1px solid #99acb9;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom-col:last-child {
    border-right: none; 
  }
  .numbers_size {
    font-size: 1.5rem; 
    font-weight: bold; 
  }
  
  .description {
    font-size: 1rem;
  }
    

  /* ------------- Slider Homecarousel ----------- */

  /* Slider Container */
.slider {
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%; /* Adjust width as needed */
  }
  
  /* Slider Track */
  .slide-track {
    display: flex;
    width: calc(160px * 23); /* Adjust based on number of slides */
    animation: scroll 33s linear infinite;
  }
  
  /* Individual Slide */
  .slide {
    height: 100px;
    width: 160px; /* Ensure width is consistent */
    padding: 10px;
    flex: 0 0 160px; /* Ensure slides do not shrink */
  }
  
  /* Image within Slide */
  .slider_img {
    max-width: 200px;
    min-width: 100px;
    height: auto;
  }
  
  /* Pause Animation on Hover */
  .slider:hover .slide-track {
    animation-play-state: paused;
  }
  
  /* Keyframes for Sliding */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-160px * 16)); 
    }
  }
  
  /* Text Centering */
  .text-center {
    text-align: center;
  }
  
  /* Margin Top */
  .mt-4 {
    margin-top: 1.5rem; 
  }
  
  /* Font Size for Description */
  .mb-3 {
    margin-bottom: 1rem; 
  }
  



/* ------------- Newletter ----------- */

.newsletter h1{
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight:400 !important;
    font-size: 30px ;
    line-height: 30px !important;
    color: #222629 !important;
    height: auto;
}

.newsletter p{
    /* font-family: 'Inter' !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    /* identical to box height */
    /* Gray -2 */
    color: #58646C !important;
    /* color:Black; */
    line-height: 10px !important;
    margin-bottom: 4px !important;
}

.newsletter {
    padding: 80px 0;
    /* background: #f2f2f2; */
    }
    
    .newsletter .content {
    max-width: 650px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 2; }
    .newsletter .content h2 {
    color: #243c4f;
    margin-bottom: 40px; }
    .newsletter .content .form-control {
    height: 50px;
    border-color: #aaaaaa;
    border-radius:0;
    }
    .newsletter .content.form-control:focus {
    box-shadow: none;
    border: 2px solid #243c4f;
    }
    .newsletter .content .btn {
    min-height: 50px; 
    border-radius:0;
    background: #243c4f;
    color: #fff;
    font-weight:600;
    }
    .newsletter .content p {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.4;
        color: #58646C;
        margin-bottom: 16px; 
        min-height: 20px; 
        opacity: 0; 
        transition: opacity 0.3s ease-in-out; 
      }
      
      .newsletter .content p.visible {
        opacity: 1;
      }
      
/* ---------- Candidate Search ---------- */
.details{
    margin-right: 60px;
}
.img-responsive{
    width: 60px; /* Set the desired width */
  height: auto;
}
.view-resume{
    
    padding: 7px 14px !important;
    margin-right: 12px;
    /* font-weight: 600; */
    text-align: center;
    border-radius: 0.3rem;
    line-height: 1;
    border: none;
    font-size: 12px;
    color: #fff;
    background-color: #4a62c2 !important;
    font-family: 'Poppins', sans-serif;

}
.no-view-resume{
    
    padding: 7px 14px !important;
    margin-right: 12px;
    /* font-weight: 600; */
    text-align: center;
    border-radius: 0.3rem;
    line-height: 1;
    border: none;
    font-size: 12px;
    color: #fff;
    background-color: #FF4720 !important;
    font-family: 'Poppins', sans-serif;

}
.view-profile{
    padding: 7px 14px !important;
    background-color: #17a2b8;
    font-family: 'Poppins', sans-serif;
    /* font-weight: 600; */
    color: #fff;
    border: none;
    font-size: 12px;
    height: 28px;
    border-radius: 0.3rem;
    margin-bottom: 12px;
    font-weight: 400;
}
.download-btn{
    padding: 7px 14px !important;
    margin-right: 12px;
    /* font-weight: 600; */
    text-align: center;
    border-radius: 0.3rem;
    line-height: 1;
    border: none;
    font-size: 12px;
    color: #fff;
    background-color: #198754 !important;
    border-color: #198754;
    font-family: 'Poppins', sans-serif;
}
.download-btn2{
    padding: 7px 14px !important;
    margin-right: 12px;
    /* font-weight: 600; */
    text-align: center;
    border-radius: 0.3rem;
    line-height: 1;
    /* border: none; */
    font-size: 12px;
    color: #000000;
    background-color: #ffffff !important;
    border:1px solid #198754;
    font-family: 'Poppins', sans-serif;
}

/* ----------------- Job post ---------------- */
#about_company{
    height:auto
}

/* ---------- Table Head -------- */
table td,
table th {
  border: 1px solid silver;
}

.headerSortDown:after,
.headerSortUp:after {
  content: ' ';
  position: relative;
  left: 2px;
  border: 8px solid transparent;
}

.headerSortDown:after {
  top: 10px;
  border-top-color: silver;
}

.headerSortUp:after {
  bottom: 15px;
  border-bottom-color: silver;
}

.headerSortDown,
.headerSortUp {
  padding-right: 10px;
}

/* -----------Modal ------------- */
.experience-modal{
    line-height: 45px;
}
.gender-label{
    margin-right: 22px;
}
.profile-delete-btn{
    margin-left: 20px;
    font-size: 20px;
}

/* Applied Job */
.title_div{
    /* Gray +2 */

    background: #F6F6F6;
    /* Gray +1 */

    /* border: 1px solid #DADEE0; */
    border-radius: 9px;
    padding: 16px;
    background-color: white;
    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.271);
    color: white;
    margin-top: 30px;

}
.delete_btn{
    border: 1px solid #E6464F;
    border-radius: 12px;
    padding: 6px 20px !important;
    background: transparent !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    margin-left:16px;

    /* Warning red */

    color: #E6464F;
}
.apply_now_btn1{
    background: #5FC2EC;
    padding: 3px 20px !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 29px;
    color: #F7FCFF;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.36);
    border:none;
    border-radius: 12px;
}
.job_title{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 22px !important;
    line-height: 29px !important;
    color: #222629 !important;
    margin-bottom:10px;
}
.company_name{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #2D3133 !important;
    
}
.applied_on_text{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 19px !important;
    color: #BDBDBD !important;

    
}
.tabs {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .tabs li {
    padding: 10px;
    background-color: #f6f4f4;
    border-right: 1px solid white;
    cursor: pointer;
    transition: 0.3s;
    
    border-radius: 16px 16px 0px 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

/* Blue -2 */

color: #3B697C;
padding: 8px 40px;
  }
  .tabs li.active {
    background: #8EDBF3;
    border: 1px solid #4893B3;

}
.content {
/* background-color: #ddd; */
padding: 1px;
}
.content > div {
padding: 20px;
}
.content > div:not(:first-child) {
display: none;
}
.applied_tag{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 20px;
    gap: 10px;
    background: #8EDBF3;
    border:none;
    border-radius: 12px;

    color: black;
}

/* Authentication */
.otp-message{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color:gray;
    padding: 23px;
}

/* Add Access */
.card-header{
    padding: 9px;
    background: #58be2e;
    /* margin: 2rem; */
    color: #fff;
    font-weight: 600;
    border: none;
    box-shadow: none;
}
.card-header-package{
    padding: 9px;
    /* padding: 12px; */
    width:100%;
    height: 34px;
    /* margin-right: 23333px; */
    background: #58be2e;
    /* margin: 2rem; */
    color: #fff;
    font-weight: 600;
    border: none;
    box-shadow: none;
}
.add-acess-child{
    padding: 16px;
}
.add-acess-submit{
    padding: 12px 44px 12px 44px!important ;
    background-color:  #58be2e !important;
    border: none;
    color: white;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 12px;
}

/* Sign In */

.fw-weight{
    font-weight: 500!important;
}

/* React select */
.react-select{
    border-radius: 42px !important;
}

/* Approved Jobs */

.nav-jobs .nav-link {
   
    color: #000000 !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 400 !important;
}

.nav-jobs .nav-link.active {
   
    border-bottom: 3px solid #2BDBB2;
    color: #000000 !important;
    font-family: 'Poppins', sans-serif;
}


/* ------- Payment --------- */
.card-header-payment{
    padding: 4px;
    /* background: #58be2e; */
    /* margin: 2rem; */
    /* color: #fff; */
    font-weight: 600;
    font-family: var(--primary-font-family);
    border: none;
    box-shadow: none;
}



  .css-13cymwt-control{
    margin: 0 !important;
    padding: 0 !important;
    /* height:32px !important; */
    /* line-height: 1 !important; */
    /* max-height: 1px !important; */
    /* border: 1px solid red !important; */
  }
  .css-t3ipsp-control{
    margin: 0 !important;
    padding: 0 !important;
    /* height:32px !important; */
    /* line-height: 1 !important; */
  }
  .css-3w2yfm-ValueContainer{
    margin: 0 !important;
    padding: 0 !important;
    /* height:32px !important; */
    /* line-height: 1 !important; */
  }
  .css-qbdosj-Input{
    margin: 0 !important;
    padding: 0 !important;
    /* border: 1px solid red !important; */
    /* height: 12px !important; */
  }


  /* Applicant Profile */

#change_status{
    /* background: #FFFFFF; */
    /* Gray */
  
    border: 1px solid #A0AAB2;
    border-radius: 12px;
    padding: 8px;
  }
  #update_status{
    background: #2CE0B6;
    border-radius: 24px;
    border:none ;
    padding: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #F7FCFF;
  
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.36);
    
  }
  
  .user_name{
     
    font-family: "gilroy";
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 28px;
    margin-top: 20px;
    text-transform: capitalize;
  }
  .grey_div{
    background-color: #F6F6F6;
    padding: 23px;
    border: 1px solid #DADEE0;
    border-radius: 16px;
    margin-top: 20px;
  }
  .div1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
  }
  
  .profile_completion p{
    
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 29px !important;
    /* identical to box height */
  
  
    /* Gray -2 */
  
    color: #222629 !important;
  
  }
  .profile_heading{
    font-family: "gilroy";
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 29px;
    color: #222629;
  
  
    /* Gray -2 */
  
    color: #222629 !important;
  }
  
  
  .grey_div .title{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #222629;
  }
  .grey_div .desc{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #222629;
  }
  .btn_div{
    display: flex;
    justify-content: left;
    gap: 20px;
    margin-top: 20px;
  }
  .btn123{
    border: 1px solid #4893B3;
    border-radius: 12px;
    padding: 10px 28px;
    font-weight: 600;
    color: #4893B3;
  }
  
  .btn124{
   
    border-radius: 12px;
    padding: 10px 28px;
    font-weight: 600;
   
  }
  .profile_completion p{
    
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    
    
    /* Gray -2 */
    
    color: #222629;
  }
  
  /* Job Applicants*/
  
  
  
  
  
  












  /* --------------Media Query For JobseekerProfile */
